import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
type ModalKey = string;
type ModalComponent = React.FC;
interface ModalContext {
  openModal: (key: ModalKey, ModalComponent: ModalComponent) => void;
  closeModal: (key: ModalKey) => void;
}
const MSG_WARN_INVALID_MODAL_USAGE = 'Modal 사용시 ModalProvider가 상위 컴포넌트로 설정되었는지 확인바랍니다.';
export const ModalContext = React.createContext<ModalContext>({
  openModal() {
    console.warn(MSG_WARN_INVALID_MODAL_USAGE);
  },
  closeModal() {
    console.warn(MSG_WARN_INVALID_MODAL_USAGE);
  }
});

/*
 * App 컴포넌트 트리의 root에 ModalRoot을 위치해주세요.
 * 모든 Modal은 ModalRoot element 아래 render 됩니다.
 */
const MODAL_ROOT_ELEMENT_ID = 'modal-root';
export const ModalRoot = () => <div id={MODAL_ROOT_ELEMENT_ID} data-sentry-component="ModalRoot" data-sentry-source-file="index.tsx" />;
const ModalProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  // Mount된 컴포넌트 중 useModal을 통해 사용하는 모달 컴포넌트들을 id => React.FC 로 관리하는 key-value 객체
  const [modals, setModals] = useState<Record<ModalKey, ModalComponent>>({});
  // DOM 트리 내 modal element들의 parent element
  const [modalRoot, setModalRoot] = useState<Element>();
  const contextValue: ModalContext = useMemo(() => ({
    openModal(keyOpen, ModalComponent) {
      setModals(modals => ({
        ...modals,
        [keyOpen]: ModalComponent
      }));
    },
    closeModal(keyClose) {
      setModals(modals => Object.fromEntries(Object.entries(modals).filter(([key]) => key !== keyClose)));
    }
  }), []);
  useEffect(() => {
    // DOM 트리 내 modal root을 찾기 위해 effect 안에서 진행
    const rootElement = document.getElementById(MODAL_ROOT_ELEMENT_ID);
    if (!rootElement) {
      console.warn('ModalRoot이 컴포넌트 트리 내에 존재하지 않습니다. document.body로 대체합니다.');
    }
    setModalRoot(rootElement || document.body);
  }, []);
  return <ModalContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="ModalProvider" data-sentry-source-file="index.tsx">
			{children}
			{modalRoot && ReactDOM.createPortal(<>
						{Object.entries(modals).map(([modalKey, ModalComponent]) => <ModalComponent key={modalKey} />)}
					</>, modalRoot)}
		</ModalContext.Provider>;
};
export default ModalProvider;