import dynamic from 'next/dynamic';
import React, { PropsWithChildren, useEffect } from 'react';
import { useAppSelector } from '@peoplefund/store';
import ModalProvider, { ModalRoot } from './ModalProvider';
import GlobalLayoutHeaderProvider from './GlobalLayoutHeaderProvider';
import ErrorMessageHandler from '@peoplefund/utils/errorMessageHandler';
import { WebviewHandler } from '@peoplefund/utils/webview-handler/index.util';
import { GlobalPortal } from '@peoplefund/utils/GlobalPortal';
const BasicLoading = dynamic(() => import('@peoplefund/components/common/Loading/Basic'));
const QaModeSettingModal = dynamic(() => import('./QaModeSettingModal'), {
  ssr: false
});
const AppWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  const isWebview = WebviewHandler().isWebview;
  const isLoading = useAppSelector(state => state.layout.loading.visible);
  useEffect(() => {
    if (window) {
      ErrorMessageHandler.init();
    }
  }, []);
  return <>
			{isLoading && <BasicLoading isMillion={isWebview} />}
			<GlobalPortal.Provider data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<ModalProvider data-sentry-element="ModalProvider" data-sentry-source-file="index.tsx">
					<GlobalLayoutHeaderProvider data-sentry-element="GlobalLayoutHeaderProvider" data-sentry-source-file="index.tsx">{children}</GlobalLayoutHeaderProvider>
					<QaModeSettingModal data-sentry-element="QaModeSettingModal" data-sentry-source-file="index.tsx" />
				</ModalProvider>
				<ModalRoot data-sentry-element="ModalRoot" data-sentry-source-file="index.tsx" />
			</GlobalPortal.Provider>
		</>;
};
export default AppWrapper;