import { createContext, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
const PortalContext = createContext<HTMLDivElement | null>(null);
interface PortalProviderProps {
  children: ReactNode;
}
function PortalProvider({
  children
}: PortalProviderProps) {
  const [portalContainerRef, setPortalContainerRef] = useState<HTMLDivElement | null>(null);
  return <PortalContext.Provider value={portalContainerRef} data-sentry-element="unknown" data-sentry-component="PortalProvider" data-sentry-source-file="GlobalPortal.tsx">
			{children}
			<div id="portal-container" ref={elem => {
      if (portalContainerRef !== null || elem === null) {
        return;
      }
      setPortalContainerRef(elem);
    }} />
		</PortalContext.Provider>;
}
interface PortalConsumerProps {
  children: ReactNode;
}
function PortalConsumer({
  children
}: PortalConsumerProps) {
  return <PortalContext.Consumer data-sentry-element="unknown" data-sentry-component="PortalConsumer" data-sentry-source-file="GlobalPortal.tsx">
			{portalContainerRef => {
      if (portalContainerRef === null) {
        return null;
      }
      return createPortal(children, portalContainerRef);
    }}
		</PortalContext.Consumer>;
}
export const GlobalPortal = {
  Provider: PortalProvider,
  Consumer: PortalConsumer
};